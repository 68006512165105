import '../../styles/portfolio.css';
import { Article } from './Article_modele'
import Chapter1_img from '../../assets/Blog/background_chapter1.jpg'
import img_chapter1 from '../../assets/Blog/img_article_chapter1.jpg'
import Chapter2_img from '../../assets/Blog/background_chapter2.jpg'
import img_chapter2 from '../../assets/Blog/img_article_chapter1.jpg'

export const Chapter1_article = () => {
    const story_intro = (<>
        <p>📝  Il faisait froid ce soir-là, l’air était chargé d’humidité et de fraîcheur. Une douce odeur boisée et épicée flottait autour du marché. Un mélange d’épices, de vin chaud et de bois brûlé réchauffait les cœurs </p>

        <p>Pierre resserra son écharpe autour de son cou. Il venait de terminer sa journée dans la cave familiale qu’il dirigeait avec son père en Valais. Les affaires étaient stables, mais loin d’être florissantes.</p>

        <p>Sur le chemin qui menait au marché, une question lui trottait en tête : pourquoi les concurrents attirent-ils autant de touristes alors que nous peinons à nous faire connaître ?
        <br/>En effet, la cave du voisin affichait souvent complet. Les visites guidées étaient réservées plusieurs semaines à l’avance. À l’inverse, Pierre devait se battre pour remplir ses créneaux.</p>
        <p>La réponse se trouvait dans un mot qu’il ne maîtrisait pas : <strong>la digitalisation</strong></p>
    </>
    )

    const textOne = (<>
        <p>Dans un monde de plus en plus connecté, la digitalisation est devenue une nécessité pour les entreprises, quelle que soit leur taille ou leur secteur. Elle vise à rendre les entreprises plus agiles et performantes. L’un des piliers essentiels de cette transformation numérique est la création d’un site web adapté, réactif et optimisé.
        <br/>C'est un outil indispensable pour gagner en visibilité. </p>
        <p>La digitalisation a pour objectif de rendre une entreprise plus agile et plus performante sur son secteur. <br/><strong>L’une des approches et la proposition d’un site web adapté et réactif.</strong>
   </p>
   </>
    )

    const textTwo = (<>
    <p>Le Valais grâce à  ses paysages et son attrait touristique attire des milliers de visiteurs chaque année et regorge d’entrepreneurs locaux. Pourtant, un manque de visibilité sur internet prive de nombreuses entreprises de clients potentiels.</p>
    <p>Selon une étude Google, près de 81% des consommateurs recherchent un produits ou service en ligne avec de prendre une décision d’achat. Ces habitudes de consommations sont encore plus flagrantes chez les jeunes.</p>
    </>
    )

    const textThree = (<>
    <p> En Valais, de nombreuses entreprises reposent sur des savoir-faire artisanaux ou familiaux. Un site bien conçu valorise l’authenticité et rassure les clients potentiels. </p>
    <p>Des études menés par Stanford Web Credibility Research, indique que 75% des utilisateurs jugent la crédibilité d’une entreprise via la qualité de son site web. </p>
    </>
    )

    const conclusion_text = (<>
    <p> Grâce au référencement naturel (SEO), les entreprises apparaîssent en tête des résultats google.Facilitant ainsi la capacité et trouver de nouveaux clients sans avoir besoin d’intermédiaire.</p>
    <p><strong>Pour conclure</strong>, avoir un site web en Valais est encore plus essentiel car,</p>
    <p>📌 <strong>C’est une destination prisée</strong> qui chaque année amène de nombreux visiteurs. </p>
    <p>📌 <strong>C’est une régions montagneuses</strong> où les déplacements peuvent être contraigants. Un site permet aux clients de trouver facilement les informations sans avoir à se déplacer. </p>
     <p>📌 <strong>Un site internet valorise les produits locaux.</strong> Qu’il s’agisse de vins, de fromages ou d’atisanat, une bonne présence en ligne fait découvrir les richesses du Valais.</p>
      </>
      )
      
    return (
        <div>
            <Article
                img_article={Chapter1_img}
                legend_img_article="Le manque de visibilité des entreprises"
                date="27.11.2024"
                title_story="Chapitre 1 : Le manque de visibilité"
                story_intro={story_intro}
                title_subject="Pourquoi les PME ont besoin d’un site web en Valais ? "
                textOne={textOne}
                question1="Outil indispendable pour gagner en visibilité"
                texttwo={textTwo}
                img_parttwo={img_chapter1}
                question3="Un site bien structuré et adapté aux smartphones donne confiance"
                textthree={textThree}
                conclusion="Une opportunité pour se démarquer de la concurrence"
                conclusion_text={conclusion_text}
                teaser=" 📝 Dans le prochain chapitre, découvrez quel à été la première démarche de Pierre."
                link="https://credibility.stanford.edu/"
                etude="Stanford Web Credibility Research"
            />
        </div>
    )
}
export const Chapter2_article = () => {
    const story_intro = (<>
        <p>📝  Pierre arrive au marché. Il avait rendez-vous avec Frank son ami d’enfance. <br/> Il l’attendait à un stand de vin chaud. Les deux amis se saluèrent chaleureusement et commencèrent à discuter : </p>

        <p> - Alors comment la savonnerie ? demanda Pierre, curieux.<br/>
         - Très bien ! On à pas mal de commandes en ligne en ce moment”, répondit Frank <br/>
        - Vous avez un site internet, vous ?<br/>
        - Oui, c’est moi qui l’ai fait. Rien de compliqué, avec un outil gratuit en ligne.</p>
        <p>Frank sortit son téléphone et tapa l’adresse en arborant un sourire fier, mais quelques secondes de silence gêné s’installèrent entre les deux compagnons.
        <p/> - Ah, attends … c’est un peu lent …, dit Frank</p>
        <p>La page s’ouvrit enfin. Malgré tout le site n’était pas très engageant. <br/> Un design dépassé, des images pixelisée et une navigation complexe.</p>
        <p>Frank avoua que son site n’était pas très adapté, qu’il avait peu de visite et qu’il galérait à le mettre à jour</p>
    </>
    )

    const textOne = (<>
        <p>Frank illustre bien certains désavantages des outils gratuits pour la création de sites internet :  </p>
        <p><strong> Performances limités : </strong> la lenteur peut décourager les visiteurs, surtout aujourd’hui où les utilisateurs s’attendent à ce que les pages s’affichent en quelques secondes. Les outils gratuits n’optimisent pas toujours les performances techniques, comme le poids des images, la structure du code. </p>
        <p> <strong> Designs restrictifs : </strong> es outils gratuits proposent des modèles prédéfinis, souvent peu personnalisables.  Ce qui peut conduire à un design daté et peu attractif.  
        <br/>  Cette uniformité limite également la capacité à se démarquer face à la concurrence. </p>
        <p>  <strong> Fonctionnalités limités : </strong>Ces outils ne permettent pas toujours d’ajouter des fonctionnalités essentielles telles que un système de paiement, un blog, un référencement optimisé par exemple.   </p>

   </>
    )
    const conclusion_text = (<>
    <p> Créer un site web avec un outil gratuit peux sembler attractif pour débuter, mais cela peut engendrer plusieurs complications à long terme :  </p>
    <p>📌 Des fonctionnalités limitées et difficilement personnalisables. </p>
    <p>📌 Des problèmes de maintenance et des performances insuffisantes (lenteur). </p>
     <p>📌 Une apparence peu professionnelle, qui peut nuire à la crédibilité de l’entreprise.</p>
      </>
      )
      
    return (
        <div>
            <Article
                img_article={Chapter2_img}
                legend_img_article="La première tentative"
                date="22.01.2025"
                title_story="Chapitre 2 : La première tentative"
                story_intro={story_intro}
                title_subject="Les limites des outils gratuits de création de sites web "
                textOne={textOne}
                
                conclusion="Pour conclure"
                conclusion_text={conclusion_text}
                teaser=" 📝 Dans le prochain chapitre, découvrez comment Pierre décide de prendre les choses en main."
                link="https://credibility.stanford.edu/"
                etude="Stanford Web Credibility Research"
            />
        </div>
    )
}