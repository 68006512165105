import "../../styles/blog.css"
import { ButtonNav } from "./Buttons"
import { Link } from "react-router-dom";


export const PostStructure = ({ img, img_title, title, text, routerOfChapter }) => {
    return (
        <div className="post">
              <a href={routerOfChapter} className="chapter_img_link">
            <img src={img} className="chapter_img" alt={img_title} />
            
            </a>
            <div className="chapter_text">
                <h5 className="chapter_title"> {title} </h5>
                <p className="chapter_text_intro"> {text} </p>
                <div className="btn_position">
                    <ButtonNav router={routerOfChapter} title="Lire la suite" />
                </div>
            </div>
        </div>
    )
}

